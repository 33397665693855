<template>
    <div class="account">
        <headBack>
            <template v-slot:title>
                <div class='title'>
                    我的账户
                </div>
            </template>
        </headBack>
        <div class='header_cont'></div>
        <div class='top_cont'>
            <div class='top_name'>
                <div>当前金额( 元 )</div>
                <div class='to_recharg' @click='$router.push({name:"recharge"})'>
                    充值
                </div>
            </div>
            <div class='money'>
                ￥{{dqye}}
            </div>
            <div class='freeze_money'>
                冻结金额 {{djye}}
            </div>
        </div>
        <div class='row_content'>
            <div class='row_title btm'>
                账户明细
            </div>
            <div class='list_item btm' v-for='item in list' :key='item.subType'>
                <div>{{item.subType}}</div>
                <div class='time_cont'>
                    <div class='time'>{{item.operateDate}}</div>
                    <div>¥{{item.JFNum}}</div>
                </div>
            </div>
            <div v-if='!list.length' class='no_list'>暂无明细～</div>
            <div v-on:click="getmore()" v-if="pageAll==false" class="endtext">加载更多</div>
            <div v-if="pageAll==true && list.length" class="endtext">加载完了</div>

        </div>

    </div>
</template>
<script>
import { onMounted, ref, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { Dialog } from "vant";
import { Toast } from 'vant'

import loginApi from "@axios/login";

export default {
  name: "account",
  components: {},
  data() {
    return {
      djye: 0,
      dqye:0.00,
      list:[],
      currentPage:1,
      pageAll:false
    };
  },
  mounted() {
    this.getAcount();
  },
  methods: {
    toDecimal(x) {
        let f = parseFloat(x);
        if (isNaN(f)) {
            return;
        }
        f = Math.round(x*100)/100;
        return f;
    } ,
    getAcount() {
      let formData = new FormData();
      const store = useStore();
      let list = this.list

      formData.append(
        "data",
        JSON.stringify({
          function: "getMyUserAccountList",
          userid: this.$store.state.uid,
          token:this.$store.state.token,
          login_userId_base: this.$store.state.uid,
          _validate: "1",
          type: "0",
          currentPage: this.currentPage + '',
          pageSize: "7",
          _from: "h5",
          verison: "1.95",
          newversion: "95",
          source: "h5_users_002"
        })
      );
      formData.append("encryption", false);

      loginApi.loginAction(formData).then(res => {
            console.log(res.message)
            this.djye = this.toDecimal(res.nowAmount-res.ableUseFee);
            this.dqye = res.nowAmount;
            this.list = list.concat(res.List);
            if(res.List.length<7){
                console.log("加载完了")
                this.pageAll = true
            }
      });
    },
    getmore(){
        this.currentPage=this.currentPage+1;
        this.getAcount();

    }

  }
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.no_list{
  padding:20px 0;
  text-align: center;
}
.header_cont {
  height: 118px;
}
.account {
  padding-bottom: 50px;
}
.top_cont {
  width: 635px;
  margin-left: 30px;
  height: 250px;
  background: url(https://static.yihu365.cn/img/h5Img/assets/img/my/account_top.png) no-repeat center
    center;
  background-size: 100%;
  padding-left: 35px;
  padding-right: 20px;
}
.top_name {
  display: flex;
  justify-content: space-between;
  font-size: 44px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  align-items: center;
  padding-top: 25px;
}
.to_recharg {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  width: 126px;
  height: 54px;
  background: linear-gradient(135deg, #fbb034 0%, #ff8247 100%);
  border-radius: 31px;
  line-height: 54px;
  text-align: center;
}
.money {
  font-size: 44px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  margin-top: 18px;
}
.freeze_money {
  margin-top: 20px;
  font-size: 28px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.row_content {
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 646px;
  margin-left: 30px;
  position: relative;
  padding: 0 22px;
  padding-top: 20px;
  margin-top: 36px;
  .row_title {
    font-size: 30px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #191919;
    height: 42px;
    line-height: 42px;
    padding-bottom: 10px;
  }
}

.list_item {
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313131;
  padding: 29px 0;
  .time_cont {
    display: flex;
    justify-content: space-between;
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    align-items: center;
    margin-top: 5px;
  }
  .time {
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #7f7e7e;
  }
}
.endtext{
    color:#ccc;
    font-size:24px;
    text-align:center;
    padding:20px 0;
}
</style>
